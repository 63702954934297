@import 'import';
.list {
  display: flex;
  gap: 10px;

  height: 40px;
  padding: 4px;

  border: 1px solid $gray-510;
  border-radius: 24px;
}

.trigger {
  @include font($color: $lightgrey2, $weight: 400, $size: rem(13));

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  padding: 4px 6px;

  white-space: nowrap;

  background: transparent;
  border: 0;
  border-radius: 20px;
}

.trigger:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.trigger[data-state='active'] {
  @include font($color: $black, $weight: 700);

  background-color: $blue-light;
}

[data-count='2'] .trigger {
  // animation only for 2 tabs
  &[data-state='active'] {
    animation: translate cubic-bezier(0, 0.6, 0.5, 0.95) 200ms;
  }

  &[data-state='active']:last-child {
    --orientation: -1;
  }
}

@keyframes translate {
  0% {
    transform: translateX(calc(var(--orientation, 1) * 100%));
  }

  100% {
    transform: translateX(0);
  }
}
